@import "fonts";
@import "variables";

.layout {
    display: flex;
    .list {
        padding: 0;
        width: 248px;
        flex: 0 0 248px;
        background-color: #f2f2f2;
        border-right: 1px solid #cccccc;
        .listitem {
            padding: 0;
            background-color: #f2f2f2;
            border-bottom: 1px solid #cccccc;
            .menu-item {
                width: 100%;
            }
            .menu-item-icon {
                width: 48px;
                background: #dcdcdc;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 56px;
                cursor: pointer;
                &.previous {
                    height: 48px;
                }
            }
            &.active {
                background-color: #000;
                .menu-item {
                    color: #ffffff;
                    .menu-item-icon {
                        background-color: #000;
                    }
                }
            }
        }
        &.mini-mode {
            width: 48px;
            flex: 0 0 48px;
            .listitem {
                span {
                    display: none;
                }
            }
        }
    }
    .content {
        display: flex;
        min-height: calc(100vh - 80px);
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
}

.user-info {
    display: flex;
    align-items: center;
    padding-right: 25px;
    color: #fd5b05;

    .user-name {
        font-family: "Trade Gothic LT";
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        color: #fd5b05;
        padding: 0 10px;
    }
}

pre {
    background-color: #dcdcdc;
    display: inline-block;
    margin-top: 4px;
    padding: 12px 24px;
}
